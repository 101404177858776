export const useScroll = (size: number) => {
    const scrolling = ref(false)
    if (import.meta.client) {
        window.addEventListener('scroll', () => {
            scrolling.value = window.scrollY > size;
        });
    }

    return scrolling
}
